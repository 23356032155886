import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import Box from '@mui/material/Box';
import Layout from "../components/layout";
import Seo from "../components/seo";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme, createTheme } from '@mui/material/styles';
import { useState } from 'react'
import DatePicker from '../components/DatePicker'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NumberChip as Chip } from '../components/Chip';
import MobileNumber from "../components/MobileNumber"
import { East } from '@mui/icons-material/';
import NumberChart from "../components/NumberChart"
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/styles'
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Parse from '../vendor/Parse'
import PostList from '../components/PostList'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Menu from '../components/Menu'
import ThreeCol from '../components/ThreeColLayout'
import useAuth from "../hooks/userAuth";
import { graphql } from 'gatsby'
import PostListContainer from '../components/PostListContainer'
const { useEffect } = React;


const myTheme = createTheme({

})
const IndexPage = ({ data, location }) => {
    return (
        <>
            <PostListContainer data={data?.allPost?.edges?.map(e => ({ ...e.node })) || []} />
        </>)
}

export default IndexPage

export const query = graphql`
query MyQuery4 {
  allPost (filter: {group: {eq: "tcm"}}){
    edges {
      node {
      itemId
      id
      data {
        content      
        title
        video
      }
        createdAt
      }
    }
  }
}

`